import { FC, ReactNode, useState } from "react";
import { TezosToolkit } from "@taquito/taquito";
import ConnectButton from "./connect-button";
import DisconnectButton from "./disconnect-button";
import ConnectingModal from "./connecting-modal";

const Header: FC<{ children: ReactNode }> = ({ children }) => {
  const [Tezos, setTezos] = useState<TezosToolkit>(
    new TezosToolkit("https://ghostnet.ecadinfra.com")
  );
  const [publicToken, setPublicToken] = useState<string | null>(null);
  const [wallet, setWallet] = useState<any>(null);
  const [userAddress, setUserAddress] = useState<string>("");
  const [userBalance, setUserBalance] = useState<number>(0);

  const buttonShown =
    !publicToken && !userAddress && !userBalance ? (
      <ConnectButton
        Tezos={Tezos}
        setPublicToken={setPublicToken}
        setWallet={setWallet}
        setUserAddress={setUserAddress}
        setUserBalance={setUserBalance}
        wallet={wallet}
      />
    ) : (
      <DisconnectButton
        wallet={wallet}
        setPublicToken={setPublicToken}
        setUserAddress={setUserAddress}
        setUserBalance={setUserBalance}
        setWallet={setWallet}
        setTezos={setTezos}
      />
    );

  if (publicToken && (!userAddress || isNaN(userBalance))) {
    return <ConnectingModal />;
  }

  return (
    <div>
      <header>
        <nav className=" border-gray-200 px-4 lg:px-6 py-2.5 w-auto dark:bg-gray-800 bg-slate-300">
          <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
            <div className="flex items-center lg:order-2">{buttonShown}</div>
            <div
              className="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1"
              id="mobile-menu-2"
            >
              <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
                <li>
                  <div className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">
                    TBD1
                  </div>
                </li>
                <li>
                  <div className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">
                    TBD2
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      {children}
    </div>
  );
};

export default Header;
