import "./App.css";
import Header from "./components/header";

const App = () => {
  return (
    <div>
      <Header>
        <div>
          <img
            className="mx-auto pt-10"
            src="/img/background.png"
            alt="background"
          ></img>
        </div>
      </Header>
    </div>
  );
};

export default App;
