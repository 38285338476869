const ConnectingModal = () => {
  return (
    <>
      <div
        id="hs-basic-modal"
        className="hs-overlay w-full h-full fixed top-0 left-0 z-[60] overflow-x-hidden overflow-y-auto"
      >
        <div className="flex flex-col bg-white border shadow-sm rounded-xl dark:bg-gray-800 dark:border-gray-700 dark:shadow-slate-700/[.7]">
          <div className="flex justify-between items-center py-3 px-4 border-b dark:border-gray-700">
            <h3 className="font-bold text-gray-800 dark:text-white">
              Connecting
            </h3>
          </div>
          <div className="p-4 overflow-y-auto">
            <img src="/img/YFcY.gif" alt="waiting_gif"></img>
          </div>
          <div className="flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-gray-700"></div>
        </div>
      </div>
    </>
  );
};

export default ConnectingModal;
